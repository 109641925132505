import { Instance, flow, getRoot, types } from 'mobx-state-tree';
import { IRootStore } from './Root';
import { daylesfordService } from 'services';
import { IOrderDetailsRequest, IReqCulturesFilterOptions, IReqOrders, ISendToSmartRequest } from 'services/models';
import { castArray, mergeWith } from 'lodash-es';
import { EditableIndicatorSpecs, IIndicatorsSpecs, IndicatorsSpecs } from './InquiriesStore';
import { getFilterStatus } from 'utils/utils';

const { identifier, boolean, model, map, enumeration, string, number, maybeNull, maybe, optional, array, reference } =
  types;

const limitFetchedRecords = 10;

const SpecDoc = model('SpecDoc', {
  id: string,
  createdAt: string,
  filePdf: string,
  name: string,
  singStatus: optional(enumeration(['nobody', 'provider', 'buyer', 'both']), 'nobody'),
  typeDoc: string,
  signatures: maybeNull(array(model({ companyName: string, date: string, userName: string }))),
});

const SpecBuyer = model('SpecBuyer', {
  blocked: boolean,
  docFoundation: maybe(string),
  email: string,
  firstName: string,
  id: string,
  lastName: string,
  phone: string,
  verified: maybe(boolean),
});
const SpecOrder = model('SpecOrder', {
  id: identifier,
  isDm: false,
  culture: number,
  specDoc: maybeNull(SpecDoc),
  basis: string,
  signer: maybeNull(string),
  providerName: maybeNull(string),
  buyerName: string,
  buyers: maybeNull(array(SpecBuyer)),
  price: string,
  priceWithNds: maybe(string),
  contractVolume: string,
  status: maybeNull(number),
  startDate: string,
  endDate: string,
  indicators: array(IndicatorsSpecs),
  number: number,
  createdAt: maybeNull(string),
  date: maybeNull(string),
  company: maybeNull(string),
  user: string,
  docFoundation: string,
  destinationWarehouse: string,
  warehouseAddress: string,
  providerTaxType: string,
  taxable: boolean,
  includeWithdrawals: boolean,
  paymentProcedure: maybeNull(number),
  contractNumber: string,
  logisticCosts: string,
  agencyCosts: string,
  additionalCosts: string,
  buyerCompany: string,
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))

  .actions((self) => {
    return {
      getFormattedIndicators() {
        return self.indicators.map((indicator) => {
          if (indicator.measure === 'string') return indicator;
          if (indicator.value) {
            const value = indicator.value.toString().replace('.', ',');
            if (!value.includes(',')) {
              indicator.value = value + ',0';
            } else {
              indicator.value = value;
            }
          }
          if (indicator.basisValue) {
            const basisValue = indicator.basisValue.toString().replace('.', ',');
            if (!basisValue.includes(',')) {
              indicator.basisValue = basisValue + ',0';
            } else {
              indicator.basisValue = basisValue;
            }
          }
          if (indicator.limitValue) {
            const limitValue = indicator.limitValue.toString().replace('.', ',');
            if (!limitValue.includes(',')) {
              indicator.limitValue = limitValue + ',0';
            } else {
              indicator.limitValue = limitValue;
            }
          }
          if (indicator.withdrawalValue) {
            const withdrawalValue = indicator.withdrawalValue.toString().replace('.', ',');
            if (!withdrawalValue.includes(',')) {
              indicator.withdrawalValue = withdrawalValue + ',0';
            } else {
              indicator.withdrawalValue = withdrawalValue;
            }
          }
          return indicator;
        });
      },
    };
  });
export interface ISpecOrder extends Instance<typeof SpecOrder> {}

const DmOrder = model('SpecOrder', {
  id: identifier,
  culture: string,
  cultureId: maybeNull(string),
  isDm: true,
  invoiceVolume: string,
  paidVolume: string,
  shippedVolume: string,
  specificationName: string,
  specDoc: maybeNull(SpecDoc),
  basis: string,
  deliveryStartDate: maybeNull(string),
  deliveryEndDate: maybeNull(string),
  destinationWarehouse: maybeNull(model({ title: string })),
  warehouseAddress: maybeNull(string),
  providerName: maybeNull(string),
  buyerName: maybeNull(string),
  buyers: maybeNull(array(SpecBuyer)),
  price: string,
  priceWithNds: maybe(string),
  silkOrderId: maybeNull(string),
  contractVolume: string,
  status: maybeNull(number),
  number: maybeNull(number),
  date: string,
  indicators: maybeNull(array(IndicatorsSpecs)),
  createdAt: maybeNull(string),
  company: maybeNull(string),
  providerTaxType: maybeNull(string),
  taxable: boolean,
});

export interface ISpecDmOrder extends Instance<typeof DmOrder> {}

export const PaymentProcedure = model('PaymentProcedure', {
  id: number,
  title: string,
  specText: string,
});

export const SpecsOrdersStore = model('SpecsOrdersStore', {
  isLoading: boolean,
  hasNextPage: boolean,
  dmHasNextPage: boolean,
  specOrders: map(SpecOrder),
  dmOrders: map(DmOrder),
  selectedStatus: optional(enumeration(['Активные', 'Завершенные', 'Отменённые']), 'Активные'),
  selectedProvider: maybeNull(string),
  selectedCultureFilter: maybeNull(string),
  pageNumber: 1,
  dmPageNumber: 1,
  editingIndicators: array(EditableIndicatorSpecs),
  specOrdersCounter: maybeNull(
    model({
      open: number,
      closed: number,
      declined: number,
      total: number,
    }),
  ),
  culturesFilterOptions: maybeNull(array(model({ value: string, label: string }))),
  paymentProcedures: maybeNull(array(PaymentProcedure)),
})
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .views((self) => ({
    get specsOrdersList() {
      return Array.from(self.specOrders.values()).flat();
    },
  }))
  .views((self) => ({
    get dmOrdersList() {
      return Array.from(self.dmOrders.values()).flat();
    },
  }))
  .views((self) => ({
    get paymentProceduresOptions() {
      if (self.paymentProcedures === null) return [];
      return self.paymentProcedures.map((procedure) => {
        return {
          value: procedure.id,
          label: procedure.title,
        };
      });
    },
  }))
  .views((self) => ({
    get hasPaymentProcedures() {
      return !!self.paymentProcedures;
    },
  }))
  .views((self) => ({
    get hasCulturesFilterOptions() {
      return !!self.culturesFilterOptions;
    },
  }))
  .views((self) => ({
    get culturesFilterOptionsList() {
      return self.culturesFilterOptions ? Array.from(self.culturesFilterOptions).flat() : [];
    },
  }))
  .views((self) => ({
    get allOrdersList() {
      return [...self.specsOrdersList, ...self.dmOrdersList].sort((a, b) => {
        const dateA = new Date((a.createdAt as string) || (a.date as string));
        const dateB = new Date((b.createdAt as string) || (b.date as string));
        return dateB.getTime() - dateA.getTime();
      });
    },
  }))

  .actions((self) => {
    return {
      setHasNextPage(value: boolean) {
        self.hasNextPage = value;
      },
    };
  })
  .actions((self) => {
    return {
      setDmHasNextPage(value: boolean) {
        self.dmHasNextPage = value;
      },
    };
  })
  .actions((self) => {
    return {
      setPageNumber(value: number) {
        self.pageNumber = value;
      },
    };
  })
  .actions((self) => {
    return {
      setdmPageNumber(value: number) {
        self.dmPageNumber = value;
      },
    };
  })
  .actions((self) => {
    return {
      resetFetchedLists() {
        self.specOrders.clear();
        self.dmOrders.clear();
      },
    };
  })
  .actions((self) => {
    return {
      setSelectedStatus(status: 'Активные' | 'Завершенные' | 'Отменённые') {
        self.resetFetchedLists();
        self.pageNumber = 1;
        self.selectedStatus = status;
      },
    };
  })
  .actions((self) => {
    return {
      setSelectedCultureFilter(culture: string) {
        self.resetFetchedLists();
        self.pageNumber = 1;
        self.selectedCultureFilter = culture;
      },
    };
  })
  .actions((self) => {
    return {
      resetSelectedCultureFilter() {
        self.resetFetchedLists();
        self.pageNumber = 1;
        self.selectedCultureFilter = null;
      },
    };
  })
  .actions((self) => {
    return {
      updateOrderIndicators(orderId: string, indicators: IIndicatorsSpecs[], isReseting = false) {
        const order = self.specOrders.get(orderId);
        if (order) {
          if (isReseting) {
            indicators.forEach((indicator) => {
              indicator.value = indicator.basisValue;
            });
          }
          order.indicators.replace(indicators);
        }
      },
    };
  })
  .actions((self) => {
    return {
      updateOrderEditingIndicators(indicators: any[]) {
        const newIndicators = indicators.map((indicator) => {
          if (indicator.measure === 'string') return indicator;
          if (indicator.value) {
            const value = parseFloat(indicator.value.replace(/,/g, '.')) || 0;
            indicator.value = Number.isInteger(value) ? value.toFixed(1) : value;
          }
          if (indicator.basisValue) {
            const value = parseFloat(indicator.basisValue.replace(/,/g, '.')) || 0;
            indicator.basisValue = Number.isInteger(value) ? value.toFixed(1) : value;
          }
          if (indicator.limitValue) {
            const value = parseFloat(indicator.limitValue.replace(/,/g, '.')) || 0;
            indicator.limitValue = Number.isInteger(value) ? value.toFixed(1) : value;
          }
          if (indicator.withdrawalValue) {
            if (indicator.withdrawalValue === '' || indicator.withdrawalValue === null) {
              indicator.withdrawalValue = '';
            } else {
              const value = parseFloat(indicator.withdrawalValue.replace(/,/g, '.')) || 0;
              indicator.withdrawalValue = Number.isInteger(value) ? value.toFixed(1) : value;
            }
          }
          return indicator;
        });
        self.editingIndicators.replace(newIndicators);
      },
    };
  })
  .actions((self) => {
    return {
      updateOrderIndicatorValue(indicatorId: number, value: string) {
        const indicator = self.editingIndicators.find((i) => i.id === indicatorId);
        if (indicator) {
          indicator.value = value;
        }
      },
    };
  })
  .actions((self) => {
    return {
      updateOrderIndicatorLimit(indicatorId: number, limit: string) {
        const indicator = self.editingIndicators.find((i) => i.id === indicatorId);
        if (indicator) {
          indicator.limitValue = limit;
        }
      },
    };
  })
  .actions((self) => {
    return {
      updateOrderIndicatorWithdrawal(indicatorId: number, withdrawal: string) {
        const indicator = self.editingIndicators.find((i) => i.id === indicatorId);
        if (indicator) {
          indicator.withdrawalValue = withdrawal;
        }
      },
    };
  })
  .actions((self) => {
    return {
      updateOrderIndicatorName(indicatorId: number, name: string) {
        const indicator = self.editingIndicators.find((i) => i.id === indicatorId);
        if (indicator) {
          indicator.name = name;
        }
      },
    };
  })
  .actions((self) => {
    return {
      updateOrderIndicatorMeasure(indicatorId: number, measure: string) {
        const indicator = self.editingIndicators.find((i) => i.id === indicatorId);
        if (indicator) {
          indicator.measure = measure;
        }
      },
    };
  })
  .actions((self) => {
    return {
      deleteIndicator(indicatorId: number) {
        const indicator = self.editingIndicators.find((i) => i.id === indicatorId);
        if (indicator) {
          self.editingIndicators.remove(indicator);
        }
      },
    };
  })
  .actions((self) => {
    return {
      addOrderIndicator() {
        const minId = Math.min(...self.editingIndicators.map((indicator) => indicator.id), -1) - 1;
        const newId = minId > 0 ? -1 : minId - 1;
        const newIndicator = {
          id: newId,
          name: '',
          measure: 'string',
          maxValue: 100,
          minValue: 0,
          step: '1',
          editable: true,
          basisValue: '1',
          value: '1',
          limitValue: '1',
          withdrawalValue: '',
          isCustom: true,
        };
        self.editingIndicators.push(newIndicator);
      },
    };
  })
  .actions((self) => ({
    process(data: any, isSingle: boolean): any {
      const dataList = castArray(data);

      const mapped = dataList.map((item) => {
        item.id = item.id.toString();

        const existing = self.specOrders?.get(item.id);
        const dataMap = self.specOrders;

        return existing
          ? mergeWith(existing, item, (_, next: any) => {
              if (Array.isArray(next)) return next;
              return;
            })
          : dataMap?.put(item);
      });
      return Array.isArray(data) ? mapped : mapped[0];
    },
  }))
  .actions((self) => ({
    processDmOrders(data: any, isSingle: boolean): any {
      const dataList = castArray(data);

      const mapped = dataList.map((item) => {
        item.id = item.orderId || item.id.toString();

        const existing = self.dmOrders?.get(item.id);
        const dataMap = self.dmOrders;

        // if (isSingle && !!self.selectedOrder && !isEqual(self.selectedOrder, existing)) {
        //   self.root.alertsStore.addNotification(`Данные от фермера обновлены`, 'notification');
        // }

        return existing
          ? mergeWith(existing, item, (_, next: any) => {
              if (Array.isArray(next)) return next;
              return;
            })
          : dataMap?.put(item);
      });
      return Array.isArray(data) ? mapped : mapped[0];
    },
  }))
  .actions((self) => {
    return {
      getCounter: flow(function* getCounter(providerId?: string, cultureName?: string): any {
        const provider = providerId || self.selectedProvider || undefined;
        const culture = cultureName || self.selectedCultureFilter || undefined;
        const ordersCounter = yield daylesfordService
          .getSpecsOrdersCounter(provider, culture)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Счетчик СИЛК предложений`, 'error'));
        const dmOrdersCounter = yield daylesfordService
          .getDMOrdersCounter(provider, culture)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Счетчик предложений`, 'error'));

        self.specOrdersCounter = {
          open: ordersCounter.data.open + dmOrdersCounter.data.open,
          closed: ordersCounter.data.closed + dmOrdersCounter.data.closed,
          declined: ordersCounter.data.declined + dmOrdersCounter.data.declined,
          total: ordersCounter.data.total + dmOrdersCounter.data.total,
        };

        return ordersCounter;
      }),
    };
  })
  .actions((self) => {
    return {
      getSpecsOrders: flow(function* getSpecsOrders(ordersRequestOptions: IReqOrders): any {
        self.isLoading = true;
        if (self.selectedProvider !== null) ordersRequestOptions.provider = self.selectedProvider;
        if (self.selectedCultureFilter !== null) ordersRequestOptions.culture_name = self.selectedCultureFilter;
        ordersRequestOptions.offset = (self.pageNumber - 1) * limitFetchedRecords;

        const orders = yield daylesfordService
          .getSpecsOrdersListInfinite(ordersRequestOptions)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Список предложений`, 'error'));
        self.setHasNextPage((orders.data?.next ?? null) != null);

        ordersRequestOptions.status = ordersRequestOptions.general_status;
        if (ordersRequestOptions.status === 'declined') self.setDmHasNextPage(false);
        if (ordersRequestOptions.status !== 'declined') {
          ordersRequestOptions.offset = (self.dmPageNumber - 1) * limitFetchedRecords;
          const dmOrders = yield daylesfordService
            .getDMOrderList(ordersRequestOptions)
            .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Список предложений`, 'error'));
          self.setDmHasNextPage((dmOrders.data?.next ?? null) != null);
          self.processDmOrders(dmOrders.data.results, false);
        }

        self.isLoading = false;

        self.process(orders.data.results, false);
        self.getCounter();
        return orders;
      }),
    };
  })
  .actions((self) => {
    return {
      getSpecsOrder: flow(function* getSpecsOrder(id: string): any {
        self.isLoading = true;
        const order = yield daylesfordService
          .getSpecsOrder(id)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Список предложений`, 'error'));
        self.setHasNextPage((order.data?.next ?? null) != null);

        self.isLoading = false;
        self.process(order.data, true);
        return order;
      }),
    };
  })
  .actions((self) => {
    return {
      getDmOrder: flow(function* getDmOrder(id: string): any {
        self.isLoading = true;
        const order = yield daylesfordService
          .getDMOrder(id)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Список предложений`, 'error'));
        self.setHasNextPage((order.data?.next ?? null) != null);

        self.isLoading = false;
        self.processDmOrders(order.data, true);
        return order;
      }),
    };
  })
  .actions((self) => {
    return {
      updateOrderDetails: flow(function* updateOrderDetails(request: IOrderDetailsRequest): any {
        self.isLoading = true;
        const order = yield daylesfordService
          .editOrderDetails(request)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: данные о сделке`, 'error'));
        self.isLoading = false;
        self.root.alertsStore.addNotification(`Детали сделки изменены`, 'success');
        self.resetFetchedLists();
        return self.process(order.data, true);
      }),
    };
  })
  .actions((self) => {
    return {
      sendDetailsToSmart: flow(function* sendDetailsToSmart(request: ISendToSmartRequest): any {
        self.isLoading = true;
        const order = yield daylesfordService
          .sendToSmart(request)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: отправка в Смарт`, 'error'));
        self.isLoading = false;
        self.root.alertsStore.addNotification(`Заявка отправлена в Смарт`, 'success');
        self.resetFetchedLists();
        return self.process(order.data, true);
      }),
    };
  })
  .actions((self) => {
    return {
      sendOrderForSign: flow(function* resignDmOrder(id: string): any {
        self.isLoading = true;
        const order = yield daylesfordService
          .sendOrderForSign(id)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: отправка сделки на подпись`, 'error'));
        self.isLoading = false;
        self.getSpecsOrder(id);
        self.root.alertsStore.addNotification(`Cделка отправлена на подпись`, 'success');
        return order;
      }),
    };
  })
  .actions((self) => {
    return {
      resignDmOrder: flow(function* resignDmOrder(id: string): any {
        self.isLoading = true;
        const order = yield daylesfordService
          .resignOrder(id)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: переподписание сделки`, 'error'));
        self.isLoading = false;
        return order;
      }),
    };
  })

  .actions((self) => {
    return {
      getCulturesFilterOptions: flow(function* getCulturesFilterOptions(): any {
        const reqOptions: IReqCulturesFilterOptions = {
          status: getFilterStatus(self.selectedStatus),
          provider: self.selectedProvider || undefined,
        };
        const culturesFilterOptions = yield daylesfordService
          .getCulturesFilterOptions(reqOptions)
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Список фильтра культур`, 'error'));

        const optionsForSelect = Array.from(culturesFilterOptions.data)
          .flat()
          .map((culture: unknown): { value: string; label: string } => {
            const name = (culture as { name: string }).name;
            return {
              value: name,
              label: name,
            };
          });
        if (self.culturesFilterOptions) {
          self.culturesFilterOptions.replace(optionsForSelect);
        } else {
          self.culturesFilterOptions = optionsForSelect as any;
        }
        return optionsForSelect;
      }),
    };
  })

  .actions((self) => {
    return {
      setProviderFilter(providerId: string) {
        self.selectedProvider = providerId;
        const ordersRequestOptions: IReqOrders = {
          limit: limitFetchedRecords,
          offset: 0,
          general_status: getFilterStatus(self.selectedStatus),
          provider: providerId,
        };

        self.getCounter(providerId);
        self.resetFetchedLists();
        self.getSpecsOrders(ordersRequestOptions);
      },
    };
  })
  .actions((self) => {
    return {
      resetProviderFilter() {
        self.selectedProvider = null;
        self.resetFetchedLists();
        self.getSpecsOrders({
          limit: limitFetchedRecords,
          offset: 0,
          general_status: getFilterStatus(self.selectedStatus),
        });
      },
    };
  })
  .actions((self) => {
    return {
      getPaymentProcedures: flow(function* getPaymentProcedures(): any {
        const paymentProcedures = yield daylesfordService
          .paymentProcedures()
          .catch((e) => self.root.alertsStore.addNotification(`${e.message}: Список порядка оплат`, 'error'));
        self.paymentProcedures = paymentProcedures.data;
        return paymentProcedures;
      }),
    };
  });
