import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useMutation, useQuery } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { useStore } from 'store/Root';
import { IRouteParams } from 'utils/models';
import { getMergedStatuses } from 'utils/utils';
import { ProgressBar } from './components/ProgressBar';
import { Loader } from 'uikit/Loader';
import { AwaitNotification } from 'components/Inquiries/pages/InquiryPage';
import { ProgressCheckmarkIcon } from 'uikit/Icons/ProgressCheckmarkIcon';
import { useCultures } from 'hooks/useCultures';
import { OrderProgressTracker } from 'components/Orders/components/OrderProgressTracker';
import {
  IndicatorsRow,
  IndicatorName,
  IndicatorValue,
  IndicatorValueLabel,
  IndicatorsHeader,
} from 'components/Inquiries/pages/InquiryPage';
import { DownloadIcon } from 'uikit/Icons/DownloadIcon';
import { BackIcon } from 'uikit/Icons/BackIcon';
import { routes } from 'routes/consts';
import {
  OrderTableName,
  OrderTableNote,
  OrderTableRow,
  OrderTableSubGrid,
  OrderTableValue,
  OrderTableWrapper,
} from './components/SpecOrdersDataList';
import { getProfile } from 'services/token-service';
import { downloadFile } from 'services/fileDownload';
import { SpecOrderActionsModal } from './components/SpecOrderActionsModal';
import { ISpecOrder } from 'store/SpecsOrdersStore';
import { SpecOrderIndicatorsModal } from './components/SpecOrderIndicatorsModal';
import { useWarehouses } from 'hooks/useWarehouses';
import { SpecOrderReSignModal } from './components/SpecOrderReSignModal';
import { SpecOrderBaseActionsModal } from './components/SpecOrderBaseActionsModal';
import { usePaymentProcedures } from 'hooks/usePaymentsProcedures';
import { toJS } from 'mobx';
import { SpecOrderSendToSmartModal } from './components/SpecOrderSendToSmartModal';
import { useProfiles } from 'hooks/useProfiles';

export const SpecsOrderPage: React.FC = observer(() => {
  const { id }: IRouteParams = useParams();
  const { specsOrdersStore, culturesStore, inquiriesStore, authStore } = useStore();
  useCultures(culturesStore.hasCultures, culturesStore.getCultures);
  useProfiles(authStore.hasProfiles, authStore.getProfiles);
  useWarehouses(inquiriesStore.hasWarehouses, inquiriesStore.getBuyerWarehouses);
  usePaymentProcedures(specsOrdersStore.hasPaymentProcedures, specsOrdersStore.getPaymentProcedures);

  const [showModalState, setShowModalState] = React.useState(false);
  const [showIndicatorsModalState, setShowIndicatorsModalState] = React.useState(false);
  const [showReSignModalState, setShowReSignModalState] = React.useState(false);
  const [showBaseModalState, setShowBaseModalState] = React.useState(false);
  const [showSendToSmartModalState, setShowSendToSmartModalState] = React.useState(false);

  const navigate = useNavigate();
  const handleClickBack = (): void => {
    navigate(`../${generatePath(routes.specsOrders.list.path)}`);
  };

  const paramId = id ?? '';

  const specsOrderData = useQuery(['ORDER_DATA_QUERY_KEY', paramId], () => specsOrdersStore.getSpecsOrder(paramId), {
    enabled: !!paramId,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });

  const resignMutation = useMutation((id: string) => specsOrdersStore.resignDmOrder(id), {
    onSuccess: (e) => {
      window.location.reload();
    },
  });

  useEffect(() => {
    if (specsOrderData.isSuccess) {
      const order = specsOrdersStore.specOrders.get(paramId) as ISpecOrder;
      const culture = culturesStore.cultures.find((item) => item.id === (order.culture as number));
      if (culture) {
        const indicators = culture.indicators;
        const orderIndicators = order.indicators;
        if (
          orderIndicators.length !== 0 &&
          orderIndicators.some((orderIndicator) => orderIndicator.limitValue === undefined)
        ) {
          const mergedIndicators = indicators.map((cultureIndicator) => {
            const orderIndicator = orderIndicators.find((orderIndicator) => orderIndicator.id === cultureIndicator.id);
            if (orderIndicator) {
              const newIndicator = {
                ...cultureIndicator,
                value: orderIndicator.value,
                name: orderIndicator.name,
                isCustom: false,
              };
              return newIndicator;
            } else {
              return cultureIndicator;
            }
          });
          specsOrdersStore.updateOrderIndicators(paramId, mergedIndicators);
          specsOrdersStore.updateOrderEditingIndicators(toJS(mergedIndicators));
        } else if (orderIndicators.length === 0)
          specsOrdersStore.updateOrderEditingIndicators(toJS(culture.indicators));
      } else specsOrdersStore.updateOrderEditingIndicators(toJS(order.indicators));
    }
  }, [specsOrderData.isSuccess]);

  if (specsOrderData.isLoading || culturesStore.isLoading || specsOrdersStore.isLoading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    );
  }

  const {
    indicators,
    status,
    basis,
    culture,
    createdAt,
    startDate,
    endDate,
    docFoundation,
    contractVolume,
    number,
    providerName,
    buyerName,
    buyerCompany,
    signer,
    warehouseAddress,
    specDoc,
    price,
    taxable,
    priceWithNds,
    destinationWarehouse,
    buyers,
    contractNumber,
    includeWithdrawals,
    paymentProcedure,
    logisticCosts,
    agencyCosts,
    additionalCosts,
    getFormattedIndicators,
  } = specsOrdersStore.specOrders?.get(paramId) as ISpecOrder;

  const mergedStatus = getMergedStatuses(basis as string, status as number);
  const title = culturesStore.getCultureName(culture as number);
  const isCPT = basis === 'CPT';
  const cultureFromStore = culturesStore.cultures.find((item) => item.id === (culture as number));
  const warehouseFromStore = inquiriesStore.warehousesList.find((item) => item.id === destinationWarehouse);
  const orderWarehouse = warehouseFromStore
    ? { value: warehouseFromStore.id, label: warehouseFromStore.title }
    : { value: 'fake', label: 'fake' };

  const formattedIndicators =
    indicators.length > 0 ? getFormattedIndicators() : cultureFromStore?.getFormattedIndicators();

  if (specsOrdersStore.editingIndicators.length === 0) {
    indicators.length > 0
      ? specsOrdersStore.updateOrderEditingIndicators(toJS(indicators))
      : specsOrdersStore.updateOrderEditingIndicators(cultureFromStore ? toJS(cultureFromStore?.indicators) : []);
  }
  const createdDate = format(new Date(createdAt || ''), 'dd MMMM yyyy', { locale: ru });
  const startOrderDate = format(new Date(startDate || ''), 'dd.MM.yyyy', { locale: ru });
  const endOrderDate = format(new Date(endDate || ''), 'dd.MM.yyyy', { locale: ru });

  const totalPrice = Number(price) * Number(contractVolume);
  const extReg = /(?:\.([^.]+))?$/;

  const profile = getProfile();
  const isOwner = profile?.type === 'owner';

  const buyersNames =
    isOwner && buyers !== null ? buyers?.map((buyer) => buyer.lastName + ' ' + buyer.firstName).join(', ') : '';

  const canMakeChanges = (status as number) === 5;

  const handleResignClick = () => {
    resignMutation.mutate(paramId);
    setShowReSignModalState(false);
  };

  return (
    <PageWrapper>
      <BackLink onClick={handleClickBack}>
        <BackIcon />
        Назад
      </BackLink>
      <OrderWrapper>
        <OrderHeader>
          <HeaderWrapper>
            {title} {contractVolume} т
          </HeaderWrapper>
          <NumberWrapper>
            <span>
              № {number} от {createdDate}
            </span>
          </NumberWrapper>
        </OrderHeader>
        <ProgressWrapper>
          <OrderProgressTracker statusArr={mergedStatus.mergedStatuses} currentStatus={mergedStatus.currentStatus} />
          <ProgressBarsWrapper>
            <ProgressBar title="ОТГРУЖЕНО, ТОННЫ" currentValue={0} totalValue={Number(contractVolume)} isPurple />
            <ProgressBar title="ОПЛАЧЕНО, ₽" currentValue={0} totalValue={totalPrice} />
          </ProgressBarsWrapper>
        </ProgressWrapper>
        <OrderTableWrapper>
          {isOwner && (
            <OrderTableRow fullWidth>
              <ManagerWrapper>
                <OrderTableName>Менеджер</OrderTableName>
                <ManagersNames>{buyersNames}</ManagersNames>
              </ManagerWrapper>
            </OrderTableRow>
          )}
          <OrderTableRow>
            <OrderTableName>Объем, т</OrderTableName>
            <OrderTableValue>{contractVolume} т</OrderTableValue>
          </OrderTableRow>
          <OrderTableRow>
            <OrderTableName>Спецификация</OrderTableName>
            <OrderTableValue>
              № {number} от {createdDate}
            </OrderTableValue>
          </OrderTableRow>
          <OrderTableRow>
            <OrderTableName>Договор</OrderTableName>
            <OrderTableValue>{contractNumber}</OrderTableValue>
          </OrderTableRow>
          <OrderTableRow>
            <OrderTableName>Продавец</OrderTableName>
            <OrderTableValue>{providerName}</OrderTableValue>
          </OrderTableRow>
          <OrderTableRow>
            <OrderTableName>Менеджер</OrderTableName>
            <OrderTableValue>{buyerName}</OrderTableValue>
          </OrderTableRow>
          <OrderTableRow>
            <OrderTableName>Цена продавца, руб</OrderTableName>
            {taxable ? (
              <OrderTableSubGrid>
                <OrderTableValue>
                  {price}
                  <OrderTableNote>без НДС</OrderTableNote>
                </OrderTableValue>
                <OrderTableValue>
                  {priceWithNds as string}
                  <OrderTableNote>c НДС</OrderTableNote>
                </OrderTableValue>
              </OrderTableSubGrid>
            ) : (
              <OrderTableValue>
                {price}
                <OrderTableNote>без НДС</OrderTableNote>
              </OrderTableValue>
            )}
          </OrderTableRow>
          {canMakeChanges && (
            <MakeChangesButton type="button" disabled={!canMakeChanges} onClick={() => setShowBaseModalState(true)}>
              Внести изменения
            </MakeChangesButton>
          )}
          {showBaseModalState && (
            <SpecOrderBaseActionsModal
              orderId={id as string}
              orderPrice={price}
              specDate={createdAt || ''}
              volume={contractVolume}
              handleModalClose={() => setShowBaseModalState(false)}
              specNumber={number}
              showModalState={showBaseModalState}
              isTaxable={taxable}
              cultureId={culture as number}
              includeWithdrawals={includeWithdrawals}
              contractNumber={contractNumber}
              paymentProcedure={paymentProcedure}
              profileCompanies={authStore.profileCompanies}
              buyerCompany={{ value: buyerCompany, label: buyerName }}
            />
          )}
        </OrderTableWrapper>
      </OrderWrapper>
      <OrderDataWrapper>
        <OrderDataHeaderWrapper>
          <OrderDataHeader>Показатели качества</OrderDataHeader>
          <OrderDataSubheader>Ограничение по качеству товара/</OrderDataSubheader>
        </OrderDataHeaderWrapper>
        <OrderTableWrapper>
          <IndicatorsHeader>
            <IndicatorName>Параметр</IndicatorName>
            <IndicatorValueLabel>Базис</IndicatorValueLabel>
            <IndicatorValueLabel>Ограничение</IndicatorValueLabel>
            <IndicatorValueLabel>Съёмы</IndicatorValueLabel>
          </IndicatorsHeader>
          {formattedIndicators &&
            formattedIndicators.map((indicator) => {
              const units = () => {
                if (indicator.measure === 'percent') return '%';
                if (indicator.name.includes(', ед')) return 'ед.';
                return '';
              };
              if (indicator.measure === 'string')
                return (
                  <IndicatorsRow key={indicator.id} fullWidth>
                    <IndicatorName>{indicator.name}</IndicatorName>

                    <IndicatorValue>{indicator.basisValue}</IndicatorValue>
                  </IndicatorsRow>
                );
              else
                return (
                  <IndicatorsRow key={indicator.id}>
                    <IndicatorName>{indicator.name}</IndicatorName>
                    <IndicatorValue>
                      {indicator.value || indicator.basisValue} {units()}
                    </IndicatorValue>
                    <IndicatorValue>
                      {indicator.limitValue} {units()}
                    </IndicatorValue>
                    <IndicatorValue>{indicator.withdrawalValue}</IndicatorValue>
                  </IndicatorsRow>
                );
            })}
        </OrderTableWrapper>
        {canMakeChanges && (
          <MakeChangesButton type="button" disabled={!canMakeChanges} onClick={() => setShowIndicatorsModalState(true)}>
            Внести изменения
          </MakeChangesButton>
        )}
      </OrderDataWrapper>
      <OrderDataWrapper>
        <OrderDataHeaderWrapper>
          <OrderDataHeader>Данные для спецификации</OrderDataHeader>
          <OrderDataSubheader>Данные по условиям поставки и подписанту</OrderDataSubheader>
        </OrderDataHeaderWrapper>
        <OrderTableWrapper>
          <OrderTableRow>
            <IndicatorName>Сроки поставки</IndicatorName>
            <IndicatorValue>
              {startOrderDate} г. - {endOrderDate} г.
            </IndicatorValue>
          </OrderTableRow>
          {isCPT ? (
            <OrderTableRow>
              <IndicatorName>Склад</IndicatorName>
              <IndicatorValue>{warehouseFromStore?.title}</IndicatorValue>
            </OrderTableRow>
          ) : (
            <OrderTableRow>
              <IndicatorName>Пункт погрузки</IndicatorName>
              <IndicatorValue>{warehouseAddress}</IndicatorValue>
            </OrderTableRow>
          )}
          <OrderTableRow>
            <IndicatorName>Юр. лицо</IndicatorName>
            <IndicatorValue>{providerName}</IndicatorValue>
          </OrderTableRow>
          <OrderTableRow>
            <IndicatorName>Подписант</IndicatorName>
            <IndicatorValue>{signer}</IndicatorValue>
          </OrderTableRow>
          <OrderTableRow>
            <IndicatorName>Основания</IndicatorName>
            <IndicatorValue>{docFoundation}</IndicatorValue>
          </OrderTableRow>
        </OrderTableWrapper>

        {canMakeChanges && (
          <MakeChangesButton type="button" disabled={!canMakeChanges} onClick={() => setShowModalState(true)}>
            Внести изменения
          </MakeChangesButton>
        )}
        {showModalState && (
          <SpecOrderActionsModal
            isCPT={isCPT}
            orderId={id as string}
            orderPrice={price as string}
            startDate={startDate as string}
            selectedWarehouse={isCPT ? orderWarehouse : warehouseAddress}
            endDate={endDate as string}
            docFoundation={docFoundation as string}
            providerName={providerName as string}
            signer={signer as string}
            showModalState={showModalState}
            handleModalClose={() => setShowModalState(false)}
          />
        )}
        {showIndicatorsModalState && (
          <SpecOrderIndicatorsModal
            orderId={id as string}
            orderIndicators={specsOrdersStore.editingIndicators}
            onModalCloseWithoutSaving={() => specsOrdersStore.updateOrderEditingIndicators(toJS(indicators))}
            showModalState={showIndicatorsModalState}
            handleModalClose={() => setShowIndicatorsModalState(false)}
          />
        )}
      </OrderDataWrapper>
      <OrderDataWrapper>
        <SpecBackground>
          <SignedSpecHeaderWrapper>
            <SignedSpecHeader>Спецификация</SignedSpecHeader>
            <SignedSpecStatus>
              {status && status > 10 && (
                <>
                  <ProgressCheckmarkIcon />
                  <span>Подписан продавцом</span>
                </>
              )}
            </SignedSpecStatus>
          </SignedSpecHeaderWrapper>
          {specDoc && (
            <>
              {' '}
              <DownloadLinkWrapper
                onClick={() =>
                  downloadFile(`${process.env.REACT_APP_BACKEND_HOST}/api${specDoc.filePdf}`, specDoc.filePdf)
                }
                as="span"
              >
                <DownloadIcon />

                {/* @ts-ignore */}
                <span>Спецификация (.{extReg.exec(specDoc.filePdf)[1]})</span>
              </DownloadLinkWrapper>
            </>
          )}
          {status && status === 10 && <AwaitNotification>Ожидается подписание</AwaitNotification>}
          {canMakeChanges && (
            <MakeChangesButton
              type="button"
              disabled={!canMakeChanges}
              onClick={() => specsOrdersStore.sendOrderForSign(id as string)}
            >
              {specsOrdersStore.isLoading ? <Loader /> : 'Отправить на подписание'}
            </MakeChangesButton>
          )}
        </SpecBackground>
      </OrderDataWrapper>
      {specDoc && !canMakeChanges && (
        <div style={{ maxWidth: 720, display: 'flex' }}>
          <MakeChangesButton style={{ margin: 0 }} type="button" onClick={() => setShowReSignModalState(true)}>
            {status && status === 10 ? 'Внести изменения' : 'Внести изменения и переподписать'}
          </MakeChangesButton>
        </div>
      )}
      <div style={{ maxWidth: 720, display: 'flex' }}>
        <MakeChangesButton style={{ margin: 0 }} type="button" onClick={() => setShowSendToSmartModalState(true)}>
          Отправить в Смарт
        </MakeChangesButton>
      </div>

      {showReSignModalState && (
        <SpecOrderReSignModal
          handleReSign={handleResignClick}
          showModalState={showReSignModalState}
          handleModalClose={() => setShowReSignModalState(false)}
        />
      )}

      {showSendToSmartModalState && (
        <SpecOrderSendToSmartModal
          orderId={id as string}
          isCPT={isCPT}
          selectedWarehouse={isCPT ? orderWarehouse : warehouseAddress}
          showModalState={showSendToSmartModalState}
          handleModalClose={() => setShowSendToSmartModalState(false)}
          isTaxable={taxable}
          includeWithdrawals={includeWithdrawals}
          paymentProcedure={paymentProcedure}
          logisticCosts={logisticCosts}
          agencyCosts={agencyCosts}
          additionalCosts={additionalCosts}
        />
      )}
    </PageWrapper>
  );
});

const PageWrapper = styled.div`
  padding: 44px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const BackLink = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px;
  width: 187px;
  background-color: transparent;
  border: 1px solid #825dec;
  border-radius: 8px;
  color: #8e66fe;
  font-size: 16px;
  font-weight: 700;
`;

const OrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 24px;
  gap: 16px;
  width: 800px;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 16px;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.02);
`;

const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderWrapper = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #292929;
`;

const NumberWrapper = styled.span`
  font-size: 15px;
  color: #656565;
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ProgressBarsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

export const OrderDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.02);
  gap: 12px;
  width: 720px;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 12px;
`;

export const OrderDataHeader = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #191919;
`;

export const OrderDataSubheader = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #656565;
`;

export const OrderDataHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SpecBackground = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 16px;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: #f3f7f9;
`;

export const SignedSpecHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SignedSpecHeader = styled.span`
  font-size: 15px;
  font-weight: 600;
  color: #292929;
`;

export const SignedSpecStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #6da000;
  font-size: 15px;
`;

export const DownloadLinkWrapper = styled.a`
  display: flex;
  padding: 6px 8px;
  cursor: pointer;
  width: fit-content;
  max-width: 200px;
  align-items: center;
  gap: 12px;
  border: 1px solid #667efe;
  border-radius: 8px;
  color: #667efe;
  font-size: 15px;
  text-decoration: none;
`;

export const ManagerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ManagersNames = styled.span`
  color: #656565;
  font-size: 15px;
`;

export const MakeChangesButton = styled.button`
  margin-top: 20px;
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: #8e66fe;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #8e66fe;
  border-radius: 12px;
  padding-top: 18px;
  padding-bottom: 18px;
  &:disabled {
    background-color: #eaeaea;
    color: #b3b3b3;
    border: 1px solid #eaeaea;
  }
`;
